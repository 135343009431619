import {showSnackbar} from './app.js';

export const CRON_LIST_REQUEST = 'CRON_LIST_REQUEST';
export const CRON_LIST_SUCCESS = 'CRON_LIST_SUCCESS';
export const CRON_LIST_FAILURE = 'CRON_LIST_FAILURE';
export const CRON_LIST_RESET = 'CRON_LIST_RESET';

export const CRON_UPDATE_REQUEST = 'CRON_UPDATE_REQUEST';
export const CRON_UPDATE_SUCCESS = 'CRON_UPDATE_SUCCESS';
export const CRON_UPDATE_FAILURE = 'CRON_UPDATE_FAILURE';
export const CRON_UPDATE_RESET = 'CRON_UPDATE_RESET';

export const CRON_CREATE_REQUEST = 'CRON_CREATE_REQUEST';
export const CRON_CREATE_SUCCESS = 'CRON_CREATE_SUCCESS';
export const CRON_CREATE_FAILURE = 'CRON_CREATE_FAILURE';
export const CRON_CREATE_RESET = 'CRON_CREATE_RESET';

export const CRON_DEL_REQUEST = 'CRON_DEL_REQUEST';
export const CRON_DEL_SUCCESS = 'CRON_DEL_SUCCESS';
export const CRON_DEL_FAILURE = 'CRON_DEL_FAILURE';
export const CRON_DEL_RESET = 'CRON_DEL_RESET';

export const CRON_DEL_CONFIRM_OPEN = 'CRON_DEL_CONFIRM_OPEN';
export const CRON_DEL_CONFIRM_OK = 'CRON_DEL_CONFIRM_OK';
export const CRON_DEL_CONFIRM_CANCEL = 'CRON_DEL_CONFIRM_CANCEL';

export const CRON_RUN_CONFIRM_OPEN = 'CRON_RUN_CONFIRM_OPEN';
export const CRON_RUN_CONFIRM_OK = 'CRON_RUN_CONFIRM_OK';
export const CRON_RUN_CONFIRM_CANCEL = 'CRON_RUN_CONFIRM_CANCEL';

export const CRON_RUN_REQUEST = 'CRON_RUN_REQUEST';
export const CRON_RUN_SUCCESS = 'CRON_RUN_SUCCESS';
export const CRON_RUN_FAILURE = 'CRON_RUN_FAILURE';

export const UI_SHOW_CRON_EDIT = 'UI_SHOW_CRON_EDIT';
export const UI_CLOSE_CRON_EDIT = 'UI_CLOSE_CRON_EDIT';

export const UI_SHOW_CRON_ADD = 'UI_SHOW_CRON_ADD';
export const UI_CLOSE_CRON_ADD = 'UI_CLOSE_CRON_ADD';

var full = location.protocol+'//'+location.hostname+(location.port ? ':3001': '');

// EDIT

export const showCronEditView = (cronID) => {
  return {
    type: UI_SHOW_CRON_EDIT,
    cronID: cronID,
  }
}

export const closeCronEditView = () => {
  return { type: UI_CLOSE_CRON_EDIT }
}

// ADD

export const showCronAddView = (cronID) => {
  return {
    type: UI_SHOW_CRON_ADD,
    cronID: null,
  }
}

export const closeCronAddView = () => {
  return { type: UI_CLOSE_CRON_ADD }
}

// LIST

export const getCronList = (firmaID) => (dispatch) => {
  // Initiate loading state
  dispatch({
    type: CRON_LIST_REQUEST
  });
  return fetch(`${full}/app/api/firma/${firmaID}/cron`,{
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.authToken}`,
        }
      })
      .then(
        response => response.json()
      )
      .then(result => {
          // We can dispatch many times!
          // Here, we update the app state with the results of the API call.
          //console.log(result);
          return dispatch({
            type: CRON_LIST_SUCCESS,
            ...result
          })
        }
      )
      .catch(err => {
        console.log(err);
        dispatch({
          type: CRON_LIST_FAILURE,
          error: err
        })
      })

}

export const updateCron = (firmaID, cronJSON) => (dispatch) => {
  dispatch({
    type: CRON_UPDATE_REQUEST
  });
  return fetch(`${full}/app/api/firma/${firmaID}/cron`,{
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.authToken}`,
    },
    body: JSON.stringify(cronJSON),
  })
  .then(
    response => response.json(),
    error => {
      console.log('An error occurred.', error);
    }
  )
  .then(result => {
      dispatch({
        type: CRON_UPDATE_SUCCESS,
        result
      });
      //console.log("cron: ",result);
      // Snackbar anzeigen
      dispatch(showSnackbar('Setup gespeichert.'));
    }
  )
  .catch(err => {
    console.log(err);
    dispatch({
      type: CRON_UPDATE_FAILURE,
      error: err
    })
  })

}


export const createCron = (firmaID, cronJSON) => (dispatch) => {
  dispatch({
    type: CRON_CREATE_REQUEST
  });
  return fetch(`${full}/app/api/firma/${firmaID}/cron`,{
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.authToken}`,
    },
    body: JSON.stringify(cronJSON),
  })
  .then(
    response => response.json(),
    error => {
      console.log('An error occurred.', error);
    }
  )
  .then(result => {
      dispatch({
        type: CRON_CREATE_SUCCESS,
        result
      });
      //console.log("cron: ",result);
      // Snackbar anzeigen
      dispatch(showSnackbar('Setup gespeichert.'));
    }
  )
  .catch(err => {
    console.log(err);
    dispatch({
      type: CRON_CREATE_FAILURE,
      error: err
    })
  })

}

// Cron löschen

export const deleteCronConfirm  = (id) => {
  return {
    type: CRON_DEL_CONFIRM_OPEN,
    id
  };
}

export const deleteCronConfirmCancel  = () => {
  return {
    type: CRON_DEL_CONFIRM_CANCEL
  };
}

export const deleteCronConfirmOk = () => {
  return {
    type: CRON_DEL_CONFIRM_OK
  };
}



export const deleteCron = (firmaID,cronID) => (dispatch) => {
  // Initiate loading state
  dispatch({
    type: CRON_DEL_REQUEST
  });
  var jsondata = {CronID: cronID + ''};
  console.log('jsondata: ',jsondata);
  return fetch(`${full}/app/api/firma/${firmaID}/cron`,{
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.authToken}`,
        },
        body: JSON.stringify(jsondata),
      })
      .then(
        response => response.json(),
        // Do not use catch, because that will also catch
        // any errors in the dispatch and resulting render,
        // causing a loop of 'Unexpected batch number' errors.
        // https://github.com/facebook/react/issues/6895
        error => {
          console.log('An error occurred.', error);
        }
      )
      .then(result => {
          // We can dispatch many times!
          // Here, we update the app state with the results of the API call.
          dispatch({
            type: CRON_DEL_SUCCESS,
            cronID
          });
          // Snackbar anzeigen
          dispatch(showSnackbar('Setup gelöscht.'));
        }
      )
      .catch(err => {
        console.log(err);
        dispatch({
          type: CRON_DEL_FAILURE,
          error: err
        })
      })

}


// Cron ausführen (für eine Firma)


export const runCronConfirm  = (id) => {
  return {
    type: CRON_RUN_CONFIRM_OPEN,
    id
  };
}

export const runCronConfirmCancel  = () => {
  return {
    type: CRON_RUN_CONFIRM_CANCEL
  };
}

export const runCronConfirmOk = () => {
  return {
    type: CRON_RUN_CONFIRM_OK
  };
}



export const runCron = (firmaID, jsondata) => (dispatch) => {
  // Initiate loading state
  dispatch({
    type: CRON_RUN_REQUEST
  });;
  console.log('jsondata: ',jsondata);
  return fetch(`${full}/app/api/firma/${firmaID}/cron/run`,{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.authToken}`,
        },
        body: JSON.stringify(jsondata),
      })
      .then(
        response => response.json(),
        // Do not use catch, because that will also catch
        // any errors in the dispatch and resulting render,
        // causing a loop of 'Unexpected batch number' errors.
        // https://github.com/facebook/react/issues/6895
        error => {
          console.log('An error occurred.', error);
        }
      )
      .then(result => {
          // We can dispatch many times!
          // Here, we update the app state with the results of the API call.
          dispatch({
            type: CRON_RUN_SUCCESS,
            result
          });
          // Snackbar anzeigen
          dispatch(showSnackbar('Meldungen angelegt.'));
        }
      )
      .catch(err => {
        console.log(err);
        dispatch({
          type: CRON_RUN_FAILURE,
          error: err
        })
      })

}