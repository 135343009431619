import { ZYKLUS_TYP_FAILURE, ZYKLUS_TYP_REQUEST, ZYKLUS_TYP_RESET, ZYKLUS_TYP_SUCCESS} from '../actions/zyklus.js';

const INITIAL_STATE = {
  liste: [],
  isLoading: false,
  didInvalidate: false,
  error: {},
}


export const zyklustypen = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ZYKLUS_TYP_REQUEST:
      return {
        ...state,
        isLoading: true,
        didInvalidate: false,
        delConfirmOpen: false
      }
    case ZYKLUS_TYP_SUCCESS:

      return {
        ...state,
        liste: action.zyklustypen,
        isLoading: false,
        didInvalidate: false
      }
    case ZYKLUS_TYP_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
        didInvalidate: true
      }
    case ZYKLUS_TYP_RESET:
      return {
        ...INITIAL_STATE
      }

    default:
      return state

  }

}