import {USER_LIST_FAILURE, USER_LIST_REQUEST, USER_LIST_SUCCESS, USER_LIST_RESET,
  UI_SHOW_USER_EDIT, UI_CLOSE_USER_EDIT, UI_SHOW_USER_ADD, UI_CLOSE_USER_ADD,
  USER_UPDATE_REQUEST, USER_UPDATE_FAILURE, USER_UPDATE_SUCCESS,
  USER_CREATE_REQUEST, USER_CREATE_SUCCESS, USER_CREATE_FAILURE,
  USER_DEL_CONFIRM_OPEN, USER_DEL_CONFIRM_CANCEL, USER_DEL_CONFIRM_OK, USER_DEL_REQUEST, USER_DEL_FAILURE, USER_DEL_SUCCESS,
  FIRMEN_PERSONEN_LIST_REQUEST, FIRMEN_PERSONEN_LIST_FAILURE, FIRMEN_PERSONEN_LIST_SUCCESS} from '../actions/useradmin.js';

  const ZUORDNUNG_INITIAL_STATE = {
    personen: [],
    isLoading: false,
    didInvalidate: false,
    error: {},
  }

const INITIAL_STATE = {
  benutzer: [],
  zuordnung: ZUORDNUNG_INITIAL_STATE,
  isLoading: false,
  didInvalidate: false,
  error: {},
  ui: { delConfirmOpen: false, isBenutzerEditing: false, benutzerID: null, mode: '' }
}


export const useradmin = (state = INITIAL_STATE, action) => {
  //console.log(action);
  switch (action.type) {
    case UI_SHOW_USER_EDIT:
      return {
        ...state,
        ui: {isBenutzerEditing: true, benutzerID: action.benutzerID, mode: 'edit' },
      }
    case UI_CLOSE_USER_EDIT:
      return {
        ...state,
        ui: {isBenutzerEditing: false, benutzerID: null, mode: '' },
      }
      case UI_SHOW_USER_ADD:
        return {
          ...state,
          ui: {isBenutzerEditing: true, benutzerID: null, mode: 'add' },
        }
      case UI_CLOSE_USER_ADD:
        return {
          ...state,
          ui: {isBenutzerEditing: false, benutzerID: null, mode: '' },
        }
    case USER_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        didInvalidate: false,
      }
    case USER_LIST_SUCCESS:

      return {
        ...state,
        benutzer: action.benutzer,
        isLoading: false,
        didInvalidate: false
      }
    case USER_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
        didInvalidate: true
      }
    case USER_LIST_RESET:
      return {
        ...INITIAL_STATE
      }
    case USER_UPDATE_REQUEST:
    case USER_CREATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        didInvalidate: false,
      }
    case USER_UPDATE_FAILURE:
    case USER_CREATE_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
        didInvalidate: true
      }
    case USER_UPDATE_SUCCESS:
      return {
        ...state,
        benutzer: { ...state.benutzer, [state.ui.benutzerID]: action.result.user },
        isLoading: false,
        didInvalidate: false,
      }
    case USER_CREATE_SUCCESS:
        return {
          ...state,
          benutzer: { ...state.benutzer, [state.ud.benutzerID]: action.result.user },
          ui: {...state.ui, benutzerID: action.result.benutzer.Id, mode: 'edit'},
          isLoading: false,
          didInvalidate: false,
        }
     // DELETE DIALOG
     case USER_DEL_CONFIRM_OPEN:
      return {
        ...state,
        ui: {...state.ui, benutzerID: action.id, delConfirmOpen: true},
      }
    case USER_DEL_CONFIRM_CANCEL:
      return {
        ...state,
        ui: {...state.ui, benutzerID: null, delConfirmOpen: false},
      }
    case USER_DEL_CONFIRM_OK:
      return {
        ...state,
        //ui: {...state.ui, delConfirmOpen: false},
      }
    // DELETE
    case USER_DEL_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case USER_DEL_FAILURE:
      return {
        ...state,
        isLoading: false,
        didInvalidate: true,
        ui: {...state.ui, delConfirmOpen: false},
      }
    case USER_DEL_SUCCESS:
      const {[action.benutzerID]: remove, ...newList} = state.benutzer;
      return {
        ...state,
        //benutzer: state.benutzer.filter(t => t.ID != action.id),
        benutzer: newList,
        isLoading: false,
        didInvalidate: false,
        ui: {...state.ui, delConfirmOpen: false},
      }

    case FIRMEN_PERSONEN_LIST_REQUEST:
    case FIRMEN_PERSONEN_LIST_SUCCESS:
    case FIRMEN_PERSONEN_LIST_FAILURE:
        return {
          ...state,
          zuordnung: zuordnungReducer(state.zuordnung, action)
        }

    default:
      return state
  }
}


export const zuordnungReducer = (state = ZUORDNUNG_INITIAL_STATE, action) => {
  //console.log(action);
  switch (action.type) {
  // FirmaUndPerson-Liste
  case FIRMEN_PERSONEN_LIST_REQUEST:
    return {
      ...state,
      isLoading: true,
      didInvalidate: false,
    }
  case FIRMEN_PERSONEN_LIST_SUCCESS:

    return {
      ...state,
      personen: action.firmen_und_personen,
      isLoading: false,
      didInvalidate: false
    }
  case FIRMEN_PERSONEN_LIST_FAILURE:
    return {
      ...state,
      error: action.error,
      isLoading: false,
      didInvalidate: true
    }

  default:
      return state

  }
}

export const useradmin1 = (state = ZUORDNUNG_INITIAL_STATE, action) => {
  return {
   useradmin: useradmin(state.useradmin,action),
   zuordnung: zuordnung(state.zuordnung, action)
  }
}
