import {showSnackbar} from './app.js';

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_FAILURE = 'USER_LIST_FAILURE';
export const USER_LIST_RESET = 'USER_LIST_RESET';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAILURE = 'USER_UPDATE_FAILURE';
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET';

export const USER_CREATE_REQUEST = 'USER_CREATE_REQUEST';
export const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS';
export const USER_CREATE_FAILURE = 'USER_CREATE_FAILURE';
export const USER_CREATE_RESET = 'USER_CREATE_RESET';

export const USER_DEL_REQUEST = 'USER_DEL_REQUEST';
export const USER_DEL_SUCCESS = 'USER_DEL_SUCCESS';
export const USER_DEL_FAILURE = 'USER_DEL_FAILURE';
export const USER_DEL_RESET = 'USER_DEL_RESET';

export const USER_DEL_CONFIRM_OPEN = 'USER_DEL_CONFIRM_OPEN';
export const USER_DEL_CONFIRM_OK = 'USER_DEL_CONFIRM_OK';
export const USER_DEL_CONFIRM_CANCEL = 'USER_DEL_CONFIRM_CANCEL';

export const UI_SHOW_USER_EDIT = 'UI_SHOW_USER_EDIT';
export const UI_CLOSE_USER_EDIT = 'UI_CLOSE_USER_EDIT';

export const UI_SHOW_USER_ADD = 'UI_SHOW_USER_ADD';
export const UI_CLOSE_USER_ADD = 'UI_CLOSE_USER_ADD';

export const PERSON_CHOOSER_OPEN = 'PERSON_CHOOSER_OPEN';
export const PERSON_CHOOSER_OK = 'PERSON_CHOOSER_OK';
export const PERSON_CHOOSER_CANCEL = 'PERSON_CHOOSER_CANCEL';

export const FIRMEN_PERSONEN_LIST_REQUEST = 'FIRMEN_PERSONEN_LIST_REQUEST';
export const FIRMEN_PERSONEN_LIST_SUCCESS = 'FIRMEN_PERSONEN_LIST_SUCCESS';
export const FIRMEN_PERSONEN_LIST_FAILURE = 'FIRMEN_PERSONEN_LIST_FAILURE';
export const FIRMEN_PERSONEN_LIST_RESET = 'FIRMEN_PERSONEN_LIST_RESET';

export const USER_PW_RECOVER_REQUEST = 'USER_PW_RECOVER_REQUEST';
export const USER_PW_RECOVER_SUCCESS = 'USER_PW_RECOVER_SUCCESS';
export const USER_PW_RECOVER_FAILURE = 'USER_PW_RECOVER_FAILURE';
export const USER_PW_RECOVER_RESET = 'USER_PW_RECOVER_RESET';

var full = location.protocol+'//'+location.hostname+(location.port ? ':3001': '');

// Personenauswahl Dialog

export const choosePersonOpen  = (id) => {
  return {
    type: PERSON_CHOOSER_OPEN,
    id
  };
}

export const choosePersonCancel  = () => {
  return {
    type: PERSON_CHOOSER_CANCEL
  };
}

export const choosePersonOk = () => {
  return {
    type: PERSON_CHOOSER_OK
  };
}


// EDIT

export const showBenutzerEditView = (benutzerID) => {
  return {
    type: UI_SHOW_USER_EDIT,
    benutzerID: benutzerID,
  }
}

export const closeBenutzerEditView = () => {
  return { type: UI_CLOSE_USER_EDIT }
}

// ADD

export const showBenutzerAddView = (benutzerID) => {
  return {
    type: UI_SHOW_STANDORT_ADD,
    benutzerID: null,
  }
}

export const closeBenutzerAddView = () => {
  return { type: UI_CLOSE_STANDORT_ADD }
}

// PW RECOVER
export const resetPW = (username) => (dispatch) => {
  dispatch({
    type: USER_PW_RECOVER_REQUEST
  });
  return fetch(`${full}/app/_reset`,{
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.authToken}`,
    },
    body: JSON.stringify({username: username}),
  })
  .then(

    response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Not 200 response")
      }
    },
    error => {
      console.log('An error occurred.', error);
    }
  )
  .then(result => {
      // Snackbar anzeigen
      dispatch(showSnackbar('Mail wurde verschickt.'));
      return dispatch({
        type: USER_PW_RECOVER_SUCCESS,
        result
      });
      //console.log("person: ",result);

    }
  )
  .catch(err => {
    console.log(err);
    dispatch({
      type: USER_PW_RECOVER_FAILURE,
      error: err
    })
  })

}


// UPDATE

export const updateBenutzer = (personJSON) => (dispatch) => {
  dispatch({
    type: USER_UPDATE_REQUEST
  });
  return fetch(`${full}/app/api/user`,{
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.authToken}`,
    },
    body: JSON.stringify(personJSON),
  })
  .then(

    response => {
      if (response.ok) {
        return response.json();
      } else if (response.status==404) {
        dispatch(showSnackbar('Benutzername existiert bereits!'));
        throw new Error("Username already exists")
      } else {
        throw new Error("Not 200 response")
      }
    },
    error => {
      console.log('An error occurred.', error);
    }
  )
  .then(result => {
      dispatch({
        type: USER_UPDATE_SUCCESS,
        result
      });
      //console.log("person: ",result);
      // Snackbar anzeigen
      dispatch(showSnackbar('Benutzer gespeichert.'));
    }
  )
  .catch(err => {
    console.log(err);
    dispatch({
      type: USER_UPDATE_FAILURE,
      error: err
    })
  })

}


// LIST

export const getBenutzerList = (firmaID) => (dispatch) => {
  // Initiate loading state
  dispatch({
    type: USER_LIST_REQUEST
  });
  return fetch(`${full}/app/api/user`,{
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.authToken}`,
        }
      })
      .then(
        response => response.json()
      )
      .then(result => {
          // We can dispatch many times!
          // Here, we update the app state with the results of the API call.
          //console.log(result);
          return dispatch({
            type: USER_LIST_SUCCESS,
            ...result
          })
        }
      )
      .catch(err => {
        console.log(err);
        dispatch({
          type: USER_LIST_FAILURE,
          error: err
        })
      })

}

// Firmen- und Perosnenliste für Auswahl

export const getFirmenUndPersonenList = (firmaID) => (dispatch) => {
  // Initiate loading state
  dispatch({
    type: FIRMEN_PERSONEN_LIST_REQUEST
  });
  return fetch(`${full}/app/api/personen`,{
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.authToken}`,
        }
      })
      .then(
        response => response.json()
      )
      .then(result => {
          return dispatch({
            type: FIRMEN_PERSONEN_LIST_SUCCESS,
            ...result
          })
        }
      )
      .catch(err => {
        console.log(err);
        dispatch({
          type: FIRMEN_PERSONEN_LIST_FAILURE,
          error: err
        })
      })

}