import {showSnackbar} from './app.js';

import { refreshLogin } from './auth.js';

export const FIRMA_LIST_REQUEST = 'FIRMA_LIST_REQUEST';
export const FIRMA_LIST_SUCCESS = 'FIRMA_LIST_SUCCESS';
export const FIRMA_LIST_FAILURE = 'FIRMA_LIST_FAILURE';
export const FIRMA_LIST_RESET = 'FIRMA_LIST_RESET';

export const FIRMA_UPDATE_REQUEST = 'FIRMA_UPDATE_REQUEST';
export const FIRMA_UPDATE_SUCCESS = 'FIRMA_UPDATE_SUCCESS';
export const FIRMA_UPDATE_FAILURE = 'FIRMA_UPDATE_FAILURE';
export const FIRMA_UPDATE_RESET = 'FIRMA_UPDATE_RESET'

export const FIRMA_DEL_REQUEST = 'FIRMA_DEL_REQUEST';
export const FIRMA_DEL_SUCCESS = 'FIRMA_DEL_SUCCESS';
export const FIRMA_DEL_FAILURE = 'FIRMA_DEL_FAILURE';
export const FIRMA_DEL_RESET = 'FIRMA_DEL_RESET';

export const FIRMA_DEL_CONFIRM_OPEN = 'FIRMA_DEL_CONFIRM_OPEN';
export const FIRMA_DEL_CONFIRM_OK = 'FIRMA_DEL_CONFIRM_OK';
export const FIRMA_DEL_CONFIRM_CANCEL = 'FIRMA_DEL_CONFIRM_CANCEL';

export const UI_SHOW_FIRMA_EDIT = 'UI_SHOW_FIRMA_EDIT';
export const UI_CLOSE_FIRMA_EDIT = 'UI_CLOSE_FIRMA_EDIT';

var full = location.protocol+'//'+location.hostname+(location.port ? ':3001': '');

export const showFirmaEditView = (firmaID) => {
  return {
    type: UI_SHOW_FIRMA_EDIT,
    firmaID: firmaID,
  }
}

export const closeFirmaEditView = () => {
  return { type: UI_CLOSE_FIRMA_EDIT }
}

export const updateFirma = (firmaJSON) => (dispatch) => {
  dispatch({
    type: FIRMA_UPDATE_REQUEST
  });
  return fetch(`${full}/app/api/firma`,{
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.authToken}`,
    },
    body: JSON.stringify(firmaJSON),
  })
  .then(
    response => response.json(),
    error => {
      console.log('An error occurred.', error);
    }
  )
  .then(result => {
      dispatch({
        type: FIRMA_UPDATE_SUCCESS,
        result
      });
      // Snackbar anzeigen
      dispatch(showSnackbar('Firma gespeichert.'));
    }
  )
  .catch(err => {
    console.log(err);
    dispatch({
      type: FIRMA_UPDATE_FAILURE,
      error: err
    })
  })

}

export const getFirmaList = () => (dispatch) => {

  // Initiate loading state
  dispatch({
    type: FIRMA_LIST_REQUEST
  });
  return fetch(`${full}/app/api/firma`,{
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.authToken}`,
        }
      })
      .then(
        response => response.json()
      )
      .then(result => {
          // We can dispatch many times!
          // Here, we update the app state with the results of the API call.
          //console.log(result);
          return dispatch({
            type: FIRMA_LIST_SUCCESS,
            ...result
          })
        }
      )
      .catch(err => {
        console.log(err);
        dispatch({
          type: FIRMA_LIST_FAILURE,
          error: err
        })
      })

}

// Firma löschen

export const deleteFirmaConfirm  = (id) => {
  return {
    type: FIRMA_DEL_CONFIRM_OPEN,
    id
  };
}

export const deleteFirmaConfirmCancel  = () => {
  return {
    type: FIRMA_DEL_CONFIRM_CANCEL
  };
}

export const deleteFirmaConfirmOk = () => {
  return {
    type: FIRMA_DEL_CONFIRM_OK
  };
}



export const deleteFirma = (firmaID) => (dispatch) => {
  // Initiate loading state
  dispatch({
    type: FIRMA_DEL_REQUEST
  });
  var jsondata = {FirmaID: firmaID + ''};
  console.log('jsondata: ',jsondata);
  return fetch(`${full}/app/api/firma`,{
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.authToken}`,
        },
        body: JSON.stringify(jsondata),
      })
      .then(
        response => response.json(),
        // Do not use catch, because that will also catch
        // any errors in the dispatch and resulting render,
        // causing a loop of 'Unexpected batch number' errors.
        // https://github.com/facebook/react/issues/6895
        error => {
          console.log('An error occurred.', error);
        }
      )
      .then(result => {
          // We can dispatch many times!
          // Here, we update the app state with the results of the API call.
          dispatch({
            type: FIRMA_DEL_SUCCESS,
            firmaID
          });
          // Snackbar anzeigen
          dispatch(showSnackbar('Firma gelöscht.'));
        }
      )
      .catch(err => {
        console.log(err);
        dispatch({
          type: FIRMA_DEL_FAILURE,
          error: err
        })
      })

}