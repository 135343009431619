import { UI_SHOW_FIRMA_EDIT, UI_CLOSE_FIRMA_EDIT,
         FIRMA_LIST_FAILURE, FIRMA_LIST_RESET, FIRMA_LIST_REQUEST, FIRMA_LIST_SUCCESS,
         FIRMA_UPDATE_REQUEST, FIRMA_UPDATE_FAILURE, FIRMA_UPDATE_SUCCESS,
        FIRMA_DEL_CONFIRM_CANCEL, FIRMA_DEL_CONFIRM_OK, FIRMA_DEL_CONFIRM_OPEN,
        FIRMA_DEL_FAILURE, FIRMA_DEL_REQUEST, FIRMA_DEL_RESET, FIRMA_DEL_SUCCESS} from '../actions/firma.js';

const INITIAL_STATE = {
  firmen: [],
  isLoading: false,
  didInvalidate: false,
  error: {},
  ui: { isFirmaEditing: false, firmaID: null, delConfirmOpen: false }
}


export const firma = (state = INITIAL_STATE, action) => {
  //console.log(action);
  switch (action.type) {
    case UI_SHOW_FIRMA_EDIT:
      return {
        ...state,
        ui: {isFirmaEditing: true, firmaID: action.firmaID },
      }
    case UI_CLOSE_FIRMA_EDIT:
      return {
        ...state,
        ui: {isFirmaEditing: false, firmaID: null },
      }
    case FIRMA_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        didInvalidate: false,
        delConfirmOpen: false
      }
    case FIRMA_LIST_SUCCESS:

      return {
        ...state,
        firmen: action.firmen,
        isLoading: false,
        didInvalidate: false
      }
    case FIRMA_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
        didInvalidate: true
      }
    case FIRMA_LIST_RESET:
      return {
        ...INITIAL_STATE
      }
    case FIRMA_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        didInvalidate: false,
      }
    case FIRMA_UPDATE_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
        didInvalidate: true
      }
    case FIRMA_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        didInvalidate: false,
      }

    // DELETE DIALOG
    case FIRMA_DEL_CONFIRM_OPEN:
        return {
          ...state,
          ui: {...state.ui, firmaID: action.id, delConfirmOpen: true},
        }
      case FIRMA_DEL_CONFIRM_CANCEL:
        return {
          ...state,
          ui: {...state.ui, firmaID: null, delConfirmOpen: false},
        }
      case FIRMA_DEL_CONFIRM_OK:
        return {
          ...state,
          //ui: {...state.ui, delConfirmOpen: false},
        }
      // DELETE
      case FIRMA_DEL_REQUEST:
        return {
          ...state,
          isLoading: true,
        }
      case FIRMA_DEL_FAILURE:
        return {
          ...state,
          isLoading: false,
          didInvalidate: true,
          ui: {...state.ui, delConfirmOpen: false},
        }
      case FIRMA_DEL_SUCCESS:
        const {[action.firmaID]: remove, ...newList} = state.firmen;
        return {
          ...state,
          //firmen: state.firmen.filter(t => t.ID != action.id),
          firmen: newList,
          isLoading: false,
          didInvalidate: false,
          ui: {...state.ui, delConfirmOpen: false},
        }

    default:
      return state
  }
}