export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_DENIED = 'LOGIN_DENIED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_RESET = 'LOGIN_RESET';

export const LOGOUT = 'LOGOUT';

export const TOKEN_REFRESH_REQUEST = 'TOKEN_REFRESH_REQUEST';
export const TOKEN_REFRESH_FAILED = 'TOKEN_REFRESH_FAILED';
export const TOKEN_REFRESH_SUCCESS = 'TOKEN_REFRESH_SUCCESS';

export const RECOVERY_TOKEN_VALIDATION_REQUEST = 'RECOVERY_TOKEN_VALIDATION_REQUEST';
export const RECOVERY_TOKEN_VALIDATION_FAILED = 'RECOVERY_TOKEN_VALIDATION_FAILED';
export const RECOVERY_TOKEN_VALIDATION_SUCCESS = 'RECOVERY_TOKEN_VALIDATION_SUCCESS';
export const RECOVERY_MODE_FINISH = 'RECOVERY_MODE_FINISH';


var full = location.protocol+'//'+location.hostname+(location.port ? ':3001': '');

const jwtDecode = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

export const anmelden = (username,pw) => (dispatch) => {
  // Initiate loading state
  dispatch({
    type: LOGIN_REQUEST,
    username
  });
  return fetch(`${full}/app/login`,{
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',   // für cookies
    body: JSON.stringify({username: username, password: pw}),
  })
  .then(
    response => {
      if(response.ok) {
        return response.json();
      }
      throw new Error('Network response was not ok.');

    },
    error => {
      console.log('An error occurred.', error);
    }
  )
  .then(result => {
      localStorage.authToken = result.token;
      console.log('token',jwtDecode(result.token));
      dispatch({
        type: LOGIN_SUCCESS,
        result,
        user: jwtDecode(result.token)
      });
      // Snackbar anzeigen
      //dispatch(showSnackbar('Playlist aktualisert.'));
    }
  )
  .catch(err => {
    console.log(err);
    dispatch({
      type: LOGIN_DENIED,
      error: err
    })
  })


}

export const logout = () => {
  delete localStorage.authToken
  return { type: LOGOUT }
}

export const refreshLogin = () => (dispatch) => {
  // Initiate loading state
  dispatch({
    type: TOKEN_REFRESH_REQUEST
  });
  return fetch(`${full}/app/api/refresh_token`,{
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.authToken}`,
    }
  })
  .then(
    response => {
      if(response.ok) {
        return response.json();
      }
      throw new Error('Network response was not ok.');

    },
    error => {
      console.log('An error occurred.', error);
    }
  )
  .then(result => {
      localStorage.authToken = result.token;
      console.log('token',jwtDecode(result.token));
      dispatch({
        type: TOKEN_REFRESH_SUCCESS,
        result,
        user: jwtDecode(result.token)
      });
    }
  )
  .catch(err => {
    console.log(err);
    dispatch({
      type: TOKEN_REFRESH_FAILED,
      error: err
    })
  })


}


export const finishRecoveryMode = () => {
  return { type: RECOVERY_MODE_FINISH }
}

export const validateRToken = (token) => (dispatch) => {
  // Initiate loading state
  dispatch({
    type: RECOVERY_TOKEN_VALIDATION_REQUEST,
    token
  });
  return fetch(`${full}/app/_valid`,{
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',   // für cookies
    body: JSON.stringify({token: token}),
  })
  .then(
    response => {
      if(response.ok) {
        return response.json();
      }
      throw new Error('Network response was not ok.');

    },
    error => {
      console.log('An error occurred.', error);
    }
  )
  .then(result => {
      //localStorage.authToken = result.token;
      //console.log('token',jwtDecode(result.token));
      dispatch({
        type: RECOVERY_TOKEN_VALIDATION_SUCCESS,
        result,

      });
      // Snackbar anzeigen
      //dispatch(showSnackbar('Playlist aktualisert.'));
    }
  )
  .catch(err => {
    console.log(err);
    dispatch({
      type: RECOVERY_TOKEN_VALIDATION_FAILED,
      error: err
    })
  })


}
