export const ZYKLUS_TYP_REQUEST = 'ZYKLUS_TYP_REQUEST';
export const ZYKLUS_TYP_SUCCESS = 'ZYKLUS_TYP_SUCCESS';
export const ZYKLUS_TYP_FAILURE = 'ZYKLUS_TYP_FAILURE';
export const ZYKLUS_TYP_RESET = 'ZYKLUS_TYP_RESET';


var full = location.protocol+'//'+location.hostname+(location.port ? ':3001': '');

export const getZyklusTypList = () => (dispatch) => {
  // Initiate loading state
  dispatch({
    type: ZYKLUS_TYP_REQUEST
  });
  return fetch(`${full}/app/api/zyklustyp`,{
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.authToken}`,
        }
      })
      .then(
        response => response.json()
      )
      .then(result => {
          return dispatch({
            type: ZYKLUS_TYP_SUCCESS,
            ...result
          })
        }
      )
      .catch(err => {
        console.log(err);
        dispatch({
          type: ZYKLUS_TYP_FAILURE,
          error: err
        })
      })

}