export const UPDATE_PAGE = 'UPDATE_PAGE';
export const UPDATE_OFFLINE = 'UPDATE_OFFLINE';
export const UPDATE_WIDE_LAYOUT = 'UPDATE_WIDE_LAYOUT';
export const UPDATE_DRAWER_STATE = 'UPDATE_DRAWER_STATE';
export const OPEN_SNACKBAR = 'OPEN_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';

import { refreshLogin } from './auth.js';


export const navigate = (path) => (dispatch) => {
  // Extract the page name from path.
  const page = path === '/' ? 'dashboard' : path.slice(1);

  // Any other info you might want to extract from the path (like page type),
  // you can do here
  dispatch(loadPage(page));

  // Close the drawer - in case the *path* change came from a link in the drawer.
  dispatch(updateDrawerState(false));
};

const loadPage = (page) => (dispatch) => {
  console.log('loadpage', page);
  switch (page) {
    case 'dashboard':
      import('../components/dashboard-view.js').then((module) => {
        // Put code in here that you want to run every time when
        // navigating to view1 after my-view1.js is loaded.
      });
      break;
    case 'firmen':
      import('../components/firmen-view.js').then((module) => {
        // Put code in here that you want to run every time when
        // navigating to view1 after my-view1.js is loaded.
      });
      break;
    case 'firmaedit':
      import('../components/firmaedit-view.js').then((module) => {
        // Put code in here that you want to run every time when
        // navigating to view1 after my-view1.js is loaded.
      });
      break;
    case 'meldungen':
      import('../components/meldungen-view.js');
      break;
    case 'rechnungen':
      import('../components/rechnungen-view.js');
      break;
    case 'auswertungen':
      import('../components/auswertungen-view.js');
      break;
    case 'benutzerverwaltung':
      import('../components/useradmin-view.js');
      break;
    case 'benutzerprofil':
      import('../components/benutzerprofil-view.js');
      break;
    default:
      page = 'error404';
      import('../components/error404-view.js');
  }

  dispatch(updatePage(page));
  // Token refresh (experimental)
  dispatch(refreshLogin());
};

const updatePage = (page) => {
  return {
    type: UPDATE_PAGE,
    page
  };
};



let snackbarTimer;

export const showSnackbar = (message) => (dispatch) => {
  dispatch({
    type: OPEN_SNACKBAR,
    message
  });
  window.clearTimeout(snackbarTimer);
  snackbarTimer = window.setTimeout(() =>
    dispatch({ type: CLOSE_SNACKBAR }), 3000);
  return Promise.resolve();
};

export const updateOffline = (offline) => (dispatch, getState) => {
  // Show the snackbar only if offline status changes.
  if (offline !== getState().app.offline) {
    let message = "Sie sind jetzt " + (offline ? 'offline' : 'online') + ".";
    dispatch(showSnackbar(message));
  }
  dispatch({
    type: UPDATE_OFFLINE,
    offline
  });
};

export const updateLayout = (wideLayout) => (dispatch, getState) => {
  console.log("UPDATe WIDE LAYOUT ACTION");
  dispatch({
    type: UPDATE_WIDE_LAYOUT,
    wideLayout
  })
  // Open the drawer when we are switching to wide layout and close it when we are
  // switching to narrow.
  dispatch(updateDrawerState(wideLayout));
};

export const updateDrawerState = (opened) => (dispatch, getState) => {
  const app = getState().app;
  // Don't allow closing the drawer when it's in wideLayout.
  if (app.drawerOpened !== opened && (!app.wideLayout || opened)) {
    dispatch({
      type: UPDATE_DRAWER_STATE,
      opened
    });
  }
};
