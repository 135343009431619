import { LOGOUT, LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILED, LOGIN_DENIED, LOGIN_RESET,
  RECOVERY_TOKEN_VALIDATION_REQUEST, RECOVERY_TOKEN_VALIDATION_FAILED, RECOVERY_TOKEN_VALIDATION_SUCCESS, RECOVERY_MODE_FINISH,
  TOKEN_REFRESH_REQUEST, TOKEN_REFRESH_FAILED, TOKEN_REFRESH_SUCCESS } from '../actions/auth.js';
import { USER_PW_RECOVER_SUCCESS, USER_PW_RECOVER_REQUEST, USER_PW_RECOVER_FAILURE} from '../actions/useradmin.js';


const jwtDecode = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

const RECOVERY_INITIAL_STATE = {
  isLoading: false,
  didInvalidate: false,
  isValidated: false,
  isValid: false,
  active: false,
  error: {},
  tokenGenerated: {}    // status nachdem generatetoken aufgerufen wurde
}

const INITIAL_STATE = (token => ({
  isLoading: false,
  loginDenied: false,
  username: null,
  error: {},
  currentUser: token ? jwtDecode(token) : null,
  recovery: RECOVERY_INITIAL_STATE,
}))(localStorage.authToken)



export const auth = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
        username: action.username,
      }
    case LOGIN_SUCCESS:
      console.log(action);
      return {
        ...state,
        isLoading: false,
        loginDenied: false,
        currentUser: action.user,
      }
    case TOKEN_REFRESH_SUCCESS:
        console.log(action);
        return {
          ...state,
          loginDenied: false,
          currentUser: action.user,
        }
    case LOGIN_FAILED:
    case LOGIN_DENIED:
      return {
        ...state,
        error: action.error,
        isLoading: false,
        loginDenied: true,
      }
    case LOGIN_RESET:
    case LOGOUT:
      console.log('LOGOUT reducer');
      return {
        ...INITIAL_STATE,
        currentUser: null  // sollte eigentlich nicht notwendig sein
      }

    case RECOVERY_TOKEN_VALIDATION_REQUEST:
    case RECOVERY_TOKEN_VALIDATION_FAILED:
    case RECOVERY_TOKEN_VALIDATION_SUCCESS:
    case RECOVERY_MODE_FINISH:
    case USER_PW_RECOVER_FAILURE:
    case USER_PW_RECOVER_REQUEST:
    case USER_PW_RECOVER_SUCCESS:
        return {
          ...state,
          recovery: recoverReducer(state.recovery, action)
        }


    default:
      return state
  }
}

export const recoverReducer = (state = RECOVERY_INITIAL_STATE, action) => {
  //console.log(action);
  switch (action.type) {
  // FirmaUndPerson-Liste
  case RECOVERY_TOKEN_VALIDATION_REQUEST:
    return {
      ...state,
      isLoading: true,
      isValidated: false,
      isValid: false,
      didInvalidate: false,
      active: true   // wir beim Passwort setzen resetet
    }
  case RECOVERY_TOKEN_VALIDATION_SUCCESS:
    //console.log("action: ", action);
    return {
      ...state,
      isValid: action.result.isValid,
      isValidated: true,
      isLoading: false,
      didInvalidate: false
    }
  case RECOVERY_TOKEN_VALIDATION_FAILED:
    return {
      ...state,
      error: action.error,
      isLoading: false,
      isValidated: false,
      isValid: false,
      didInvalidate: true
    }
  case RECOVERY_MODE_FINISH:
    return {
        ...state,
        active: false,
      }
  case USER_PW_RECOVER_SUCCESS:
    return {
      ...state,
      tokenGenerated: action.result,
    }
  case USER_PW_RECOVER_REQUEST:
  case USER_PW_RECOVER_FAILURE:
      return {
        ...state,
        tokenGenerated: {},
      }

  default:
      return state

  }
}
