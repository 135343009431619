import {showSnackbar} from './app.js';

export const STANDORT_LIST_REQUEST = 'STANDORT_LIST_REQUEST';
export const STANDORT_LIST_SUCCESS = 'STANDORT_LIST_SUCCESS';
export const STANDORT_LIST_FAILURE = 'STANDORT_LIST_FAILURE';
export const STANDORT_LIST_RESET = 'STANDORT_LIST_RESET';

export const STANDORT_UPDATE_REQUEST = 'STANDORT_UPDATE_REQUEST';
export const STANDORT_UPDATE_SUCCESS = 'STANDORT_UPDATE_SUCCESS';
export const STANDORT_UPDATE_FAILURE = 'STANDORT_UPDATE_FAILURE';
export const STANDORT_UPDATE_RESET = 'STANDORT_UPDATE_RESET';

export const STANDORT_CREATE_REQUEST = 'STANDORT_CREATE_REQUEST';
export const STANDORT_CREATE_SUCCESS = 'STANDORT_CREATE_SUCCESS';
export const STANDORT_CREATE_FAILURE = 'STANDORT_CREATE_FAILURE';
export const STANDORT_CREATE_RESET = 'STANDORT_CREATE_RESET';

export const STANDORT_DEL_REQUEST = 'STANDORT_DEL_REQUEST';
export const STANDORT_DEL_SUCCESS = 'STANDORT_DEL_SUCCESS';
export const STANDORT_DEL_FAILURE = 'STANDORT_DEL_FAILURE';
export const STANDORT_DEL_RESET = 'STANDORT_DEL_RESET';

export const STANDORT_DEL_CONFIRM_OPEN = 'STANDORT_DEL_CONFIRM_OPEN';
export const STANDORT_DEL_CONFIRM_OK = 'STANDORT_DEL_CONFIRM_OK';
export const STANDORT_DEL_CONFIRM_CANCEL = 'STANDORT_DEL_CONFIRM_CANCEL';


export const UI_SHOW_STANDORT_EDIT = 'UI_SHOW_STANDORT_EDIT';
export const UI_CLOSE_STANDORT_EDIT = 'UI_CLOSE_STANDORT_EDIT';

export const UI_SHOW_STANDORT_ADD = 'UI_SHOW_STANDORT_ADD';
export const UI_CLOSE_STANDORT_ADD = 'UI_CLOSE_STANDORT_ADD';

var full = location.protocol+'//'+location.hostname+(location.port ? ':3001': '');

// EDIT

export const showStandortEditView = (standortID) => {
  return {
    type: UI_SHOW_STANDORT_EDIT,
    standortID: standortID,
  }
}

export const closeStandortEditView = () => {
  return { type: UI_CLOSE_STANDORT_EDIT }
}

// ADD

export const showStandortAddView = (standortID) => {
  return {
    type: UI_SHOW_STANDORT_ADD,
    standortID: null,
  }
}

export const closeStandortAddView = () => {
  return { type: UI_CLOSE_STANDORT_ADD }
}

// LIST

export const getStandortList = (firmaID) => (dispatch) => {
  // Initiate loading state
  dispatch({
    type: STANDORT_LIST_REQUEST
  });
  return fetch(`${full}/app/api/firma/${firmaID}/standort`,{
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.authToken}`,
        }
      })
      .then(
        response => response.json()
      )
      .then(result => {
          // We can dispatch many times!
          // Here, we update the app state with the results of the API call.
          //console.log(result);
          return dispatch({
            type: STANDORT_LIST_SUCCESS,
            ...result
          })
        }
      )
      .catch(err => {
        console.log(err);
        dispatch({
          type: STANDORT_LIST_FAILURE,
          error: err
        })
      })

}

export const updateStandort = (firmaID, standortJSON) => (dispatch) => {
  dispatch({
    type: STANDORT_UPDATE_REQUEST
  });
  return fetch(`${full}/app/api/firma/${firmaID}/standort`,{
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.authToken}`,
    },
    body: JSON.stringify(standortJSON),
  })
  .then(
    response => response.json(),
    error => {
      console.log('An error occurred.', error);
    }
  )
  .then(result => {
      dispatch({
        type: STANDORT_UPDATE_SUCCESS,
        result
      });
      //console.log("standort: ",result);
      // Snackbar anzeigen
      dispatch(showSnackbar('Standort gespeichert.'));
    }
  )
  .catch(err => {
    console.log(err);
    dispatch({
      type: STANDORT_UPDATE_FAILURE,
      error: err
    })
  })

}


export const createStandort = (firmaID, standortJSON) => (dispatch) => {
  dispatch({
    type: STANDORT_CREATE_REQUEST
  });
  return fetch(`${full}/app/api/firma/${firmaID}/standort`,{
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.authToken}`,
    },
    body: JSON.stringify(standortJSON),
  })
  .then(
    response => response.json(),
    error => {
      console.log('An error occurred.', error);
    }
  )
  .then(result => {
      dispatch({
        type: STANDORT_CREATE_SUCCESS,
        result
      });
      //console.log("standort: ",result);
      // Snackbar anzeigen
      dispatch(showSnackbar('Standort gespeichert.'));
    }
  )
  .catch(err => {
    console.log(err);
    dispatch({
      type: STANDORT_CREATE_FAILURE,
      error: err
    })
  })

}

// Standort löschen

export const deleteStandortConfirm  = (id) => {
  return {
    type: STANDORT_DEL_CONFIRM_OPEN,
    id
  };
}

export const deleteStandortConfirmCancel  = () => {
  return {
    type: STANDORT_DEL_CONFIRM_CANCEL
  };
}

export const deleteStandortConfirmOk = () => {
  return {
    type: STANDORT_DEL_CONFIRM_OK
  };
}



export const deleteStandort = (firmaID,standortID) => (dispatch) => {
  // Initiate loading state
  dispatch({
    type: STANDORT_DEL_REQUEST
  });
  var jsondata = {StandortID: standortID + ''};
  console.log('jsondata: ',jsondata);
  return fetch(`${full}/app/api/firma/${firmaID}/standort`,{
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.authToken}`,
        },
        body: JSON.stringify(jsondata),
      })
      .then(
        response => response.json(),
        // Do not use catch, because that will also catch
        // any errors in the dispatch and resulting render,
        // causing a loop of 'Unexpected batch number' errors.
        // https://github.com/facebook/react/issues/6895
        error => {
          console.log('An error occurred.', error);
        }
      )
      .then(result => {
          // We can dispatch many times!
          // Here, we update the app state with the results of the API call.
          dispatch({
            type: STANDORT_DEL_SUCCESS,
            standortID
          });
          // Snackbar anzeigen
          dispatch(showSnackbar('Standort gelöscht.'));
        }
      )
      .catch(err => {
        console.log(err);
        dispatch({
          type: STANDORT_DEL_FAILURE,
          error: err
        })
      })

}