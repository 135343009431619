import { REPORT_JAHR_LIST_REQUEST } from "./report";

export const RECHNUNG_JAHR_LIST_REQUEST = 'RECHNUNG_JAHR_LIST_REQUEST';
export const RECHNUNG_JAHR_LIST_SUCCESS = 'RECHNUNG_JAHR_LIST_SUCCESS';
export const RECHNUNG_JAHR_LIST_FAILURE = 'RECHNUNG_JAHR_LIST_FAILURE';
export const RECHNUNG_JAHR_LIST_RESET = 'RECHNUNG_JAHR_LIST_RESET';

export const RECHNUNG_FIRMA_JAHR_LIST_REQUEST = 'RECHNUNG_FIRMA_JAHR_LIST_REQUEST';
export const RECHNUNG_FIRMA_JAHR_LIST_SUCCESS = 'RECHNUNG_FIRMA_JAHR_LIST_SUCCESS';
export const RECHNUNG_FIRMA_JAHR_LIST_FAILURE = 'RECHNUNG_FIRMA_JAHR_LIST_FAILURE';
export const RECHNUNG_FIRMA_JAHR_LIST_RESET = 'RECHNUNG_FIRMA_JAHR_LIST_RESET';

var full = location.protocol+'//'+location.hostname+(location.port ? ':3001': '');


export const getRechnungJahrList = (jahr) => (dispatch) => {
  // Initiate loading state
  dispatch({
    type: RECHNUNG_JAHR_LIST_REQUEST
  });
  return fetch(`${full}/app/api/rechnung?jahr=${jahr}`,{
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.authToken}`,
        }
      })
      .then(
        response => response.json()
      )
      .then(result => {
          // We can dispatch many times!
          // Here, we update the app state with the results of the API call.
          //console.log(result);
          return dispatch({
            type: RECHNUNG_JAHR_LIST_SUCCESS,
            ...result
          })
        }
      )
      .catch(err => {
        console.log(err);
        dispatch({
          type: RECHNUNG_JAHR_LIST_FAILURE,
          error: err
        })
      })

}
