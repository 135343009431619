import { UI_SHOW_CRON_EDIT, UI_CLOSE_CRON_EDIT, UI_SHOW_CRON_ADD, UI_CLOSE_CRON_ADD,
  CRON_LIST_FAILURE, CRON_LIST_RESET, CRON_LIST_REQUEST, CRON_LIST_SUCCESS,
  CRON_UPDATE_REQUEST, CRON_UPDATE_FAILURE, CRON_UPDATE_SUCCESS,
  CRON_CREATE_REQUEST, CRON_CREATE_SUCCESS, CRON_CREATE_FAILURE,
  CRON_DEL_CONFIRM_OPEN, CRON_DEL_CONFIRM_CANCEL, CRON_DEL_CONFIRM_OK, CRON_DEL_REQUEST, CRON_DEL_FAILURE, CRON_DEL_SUCCESS,
  CRON_RUN_CONFIRM_OK, CRON_RUN_CONFIRM_CANCEL, CRON_RUN_CONFIRM_OPEN} from '../actions/cron.js';


const INITIAL_STATE = {
  cronlist: [],
  isLoading: false,
  didInvalidate: false,
  error: {},
  ui: { runCronConfirmOpen:false, delConfirmOpen: false, isCronEditing: false, cronID: null, mode: '' }
}


export const cron = (state = INITIAL_STATE, action) => {
  //console.log(action);
  switch (action.type) {
    case UI_SHOW_CRON_EDIT:
      return {
        ...state,
        ui: {isCronEditing: true, cronID: action.cronID, mode: 'edit' },
      }
    case UI_CLOSE_CRON_EDIT:
      return {
        ...state,
        ui: {isCronEditing: false, cronID: null, mode: '' },
      }
      case UI_SHOW_CRON_ADD:
        return {
          ...state,
          ui: {isCronEditing: true, cronID: null, mode: 'add' },
        }
      case UI_CLOSE_CRON_ADD:
        return {
          ...state,
          ui: {isCronEditing: false, cronID: null, mode: '' },
        }
    case CRON_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        didInvalidate: false,
      }
    case CRON_LIST_SUCCESS:

      return {
        ...state,
        cronlist: action.cronlist,
        isLoading: false,
        didInvalidate: false
      }
    case CRON_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
        didInvalidate: true
      }
    case CRON_LIST_RESET:
      return {
        ...INITIAL_STATE
      }
    case CRON_UPDATE_REQUEST:
    case CRON_CREATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        didInvalidate: false,
      }
    case CRON_UPDATE_FAILURE:
    case CRON_CREATE_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
        didInvalidate: true
      }
    case CRON_UPDATE_SUCCESS:
      return {
        ...state,
        cronlist: { ...state.cronlist, [action.result.cron.CronID]: action.result.cron },
        isLoading: false,
        didInvalidate: false,
      }
    case CRON_CREATE_SUCCESS:
        return {
          ...state,
          cronlist: { ...state.cronlist, [action.result.cron.CronID]: action.result.cron },
          ui: {...state.ui, cronID: action.result.cron.CronID, mode: 'edit'},
          isLoading: false,
          didInvalidate: false,
        }
     // DELETE DIALOG
     case CRON_DEL_CONFIRM_OPEN:
      return {
        ...state,
        ui: {...state.ui, cronID: action.id, delConfirmOpen: true},
      }
    case CRON_DEL_CONFIRM_CANCEL:
      return {
        ...state,
        ui: {...state.ui, cronID: null, delConfirmOpen: false},
      }
    case CRON_DEL_CONFIRM_OK:
      return {
        ...state,
        //ui: {...state.ui, delConfirmOpen: false},
      }
    // RUN DIALOG
    case CRON_RUN_CONFIRM_OPEN:
      return {
        ...state,
        ui: {...state.ui, runCronConfirmOpen: true},
      }
    case CRON_RUN_CONFIRM_CANCEL:
      return {
        ...state,
        ui: {...state.ui, runCronConfirmOpen: false},
      }
    case CRON_RUN_CONFIRM_OK:
      return {
        ...state,
        ui: {...state.ui, runCronConfirmOpen: false},
        //ui: {...state.ui, delConfirmOpen: false},
      }
    // DELETE
    case CRON_DEL_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case CRON_DEL_FAILURE:
      return {
        ...state,
        isLoading: false,
        didInvalidate: true,
        ui: {...state.ui, delConfirmOpen: false},
      }
    case CRON_DEL_SUCCESS:
      const {[action.cronID]: remove, ...newList} = state.cronlist;
      return {
        ...state,
        //cronlist: state.cronlist.filter(t => t.ID != action.id),
        cronlist: newList,
        isLoading: false,
        didInvalidate: false,
        ui: {...state.ui, delConfirmOpen: false},
      }

    default:
      return state
  }
}