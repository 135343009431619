import { createSelector } from 'reselect';

import { MELDUNG_LIST_FAILURE, MELDUNG_LIST_RESET,
  MELDUNG_LIST_SUCCESS, MELDUNG_LIST_REQUEST,
  MELDUNG_TYP_REQUEST, MELDUNG_TYP_RESET, MELDUNG_TYP_SUCCESS, MELDUNG_TYP_FAILURE,
  MELDUNG_DEL_CONFIRM_CANCEL, MELDUNG_DEL_CONFIRM_OK, MELDUNG_DEL_CONFIRM_OPEN,
  UI_SHOW_MELDUNG_EDIT, UI_CLOSE_MELDUNG_EDIT,
  MELDUNG_DATEN_REQUEST, MELDUNG_DATEN_FAILURE, MELDUNG_DATEN_RESET, MELDUNG_DATEN_SUCCESS,
  MELDUNG_UPDATE_REQUEST, MELDUNG_UPDATE_FAILURE, MELDUNG_UPDATE_SUCCESS, MELDUNG_UPDATE_RESET,
  MELDUNG_DELETE_REQUEST, MELDUNG_DELETE_FAILURE, MELDUNG_DELETE_SUCCESS,
  MELDUNG_BYZYKLUS_FAILURE, MELDUNG_BYZYKLUS_REQUEST, MELDUNG_BYZYKLUS_RESET, MELDUNG_BYZYKLUS_SUCCESS} from  '../actions/meldung.js';

  // Selector


export const masseSelector = state => state.meldung.detail.daten

export const summeMasseSelector = createSelector(
  masseSelector,
  masselist => masselist.reduce((acc, item) => acc + item.Masse.Float64, 0)
);

  // -------------------------------------
  // Meldungen

const INITIAL_STATE = {
  meldungen: [],
  meldungstypen: [],
  isLoading: false,
  didInvalidate: false,
  error: {},
  ui: { delConfirmOpen: false, isMeldungEditing: false, zyklusID: null,  mTypID: null, sbkID: null, mode: '' },
  detail: {isLoading : false, didInvalidate: false, error:{}, daten: []}
}

export const meldung = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Meldungsliste
    case MELDUNG_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        didInvalidate: false,
        delConfirmOpen: false
      }
    case MELDUNG_LIST_SUCCESS:

      return {
        ...state,
        meldungen: action.meldungen,
        isLoading: false,
        didInvalidate: false
      }
    case MELDUNG_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
        didInvalidate: true
      }
    case MELDUNG_LIST_RESET:
      return {
        ...INITIAL_STATE
      }
    // LÖSCHEN
    case MELDUNG_DELETE_REQUEST:
      return {
        ...state,
        isLoading: true,
        didInvalidate: false,
      }
    case MELDUNG_DELETE_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
        didInvalidate: true
      }
    case MELDUNG_DELETE_SUCCESS:
        const {[action.zyklusID]: remove, ...newList} = state.meldungen;
        return {
          ...state,
          meldungen: newList,
          error: {},
          isLoading: false,
          didInvalidate: false,
          ui: {...state.ui, delConfirmOpen: false},
        }

    // DELETE DIALOG
    case MELDUNG_DEL_CONFIRM_OPEN:
      return {
        ...state,
        ui: {...state.ui, zyklusID: action.id, delConfirmOpen: true},
      }
    case MELDUNG_DEL_CONFIRM_CANCEL:
      return {
        ...state,
        ui: {...state.ui, zyklusID: null, delConfirmOpen: false},
      }
    case MELDUNG_DEL_CONFIRM_OK:
      return {
        ...state,
        //ui: {...state.ui, delConfirmOpen: false},
      }

    // EDIT VIEW
    case UI_SHOW_MELDUNG_EDIT:
      return {
        ...state,
        ui: {isMeldungEditing: true, zyklusID: action.zyklusID, mTypID: action.mTypID, sbkID: action.sbkID, mode: 'edit' },
      }
    case UI_CLOSE_MELDUNG_EDIT:
      return {
        ...state,
        ui: {isMeldungEditing: false, zyklusID: null, mTypID: null, mode: '' },
      }
    // MeldungByZyklus
    case MELDUNG_BYZYKLUS_REQUEST:
        return {
          ...state,
          detail: {...state.detail, daten:[...state.detail.daten], isLoading: true, didInvalidate: false},
        }
    case MELDUNG_BYZYKLUS_FAILURE:
        return {
          ...state,
          detail: {...state.detail, daten:[...state.detail.daten], isLoading: false, didInvalidate: true},
        }
    case MELDUNG_BYZYKLUS_SUCCESS:
        return {
          ...state,
          detail: {...state.detail, daten:[...state.detail.daten],isLoading: false, didInvalidate: false, meldung: action.meldung},
        }
    // Meldungsdaten
    case MELDUNG_DATEN_REQUEST:
    case MELDUNG_UPDATE_REQUEST:
      return {
        ...state,
        detail: {...state.detail, isLoading: true, didInvalidate: false},
      }
    case MELDUNG_DATEN_SUCCESS:

      return {
        ...state,
        detail: {...state.detail, isLoading: false, didInvalidate: false, daten: action.meldungDaten},
      }
    case MELDUNG_DATEN_FAILURE:
    case MELDUNG_UPDATE_FAILURE:
      return {
        ...state,
        detail: {...state.detail, isLoading: false, didInvalidate: true},
      }
    case MELDUNG_UPDATE_SUCCESS:
      return {
        ...state,
        meldungen: {...state.meldungen,[action.result.meldung.ZyklusID]: action.result.meldung },
        detail: {...state.detail, isLoading: false, didInvalidate: false},
      }
    case MELDUNG_DATEN_RESET:
    case MELDUNG_UPDATE_RESET:
      return {
        ...INITIAL_STATE
      }


    // Meldungstypen

    case MELDUNG_TYP_REQUEST:
      return {
        ...state,
        isLoading: true,
        didInvalidate: false,
        delConfirmOpen: false
      }
    case MELDUNG_TYP_SUCCESS:

      return {
        ...state,
        meldungstypen: action.meldungstypen,
        isLoading: false,
        didInvalidate: false
      }
    case MELDUNG_TYP_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
        didInvalidate: true
      }
    case MELDUNG_TYP_RESET:
      return {
        ...INITIAL_STATE
      }

    default:
      return state

  }

}

// ------------------------------------------------------
// Meldungstypen

const MELDUNG_TYP_INITIAL_STATE = {
  liste: [],
  isLoading: false,
  didInvalidate: false,
  error: {},
}

export const meldungstypen = (state = MELDUNG_TYP_INITIAL_STATE, action) => {
  switch (action.type) {
    // Meldungstypen
    case MELDUNG_TYP_REQUEST:
      return {
        ...state,
        isLoading: true,
        didInvalidate: false,
        delConfirmOpen: false
      }
    case MELDUNG_TYP_SUCCESS:

      return {
        ...state,
        liste: action.meldungstypen,
        isLoading: false,
        didInvalidate: false
      }
    case MELDUNG_TYP_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
        didInvalidate: true
      }
    case MELDUNG_TYP_RESET:
      return {
        ...INITIAL_STATE
      }

    default:
      return state

  }

}