import {showSnackbar} from './app.js';

export const PERSON_LIST_REQUEST = 'PERSON_LIST_REQUEST';
export const PERSON_LIST_SUCCESS = 'PERSON_LIST_SUCCESS';
export const PERSON_LIST_FAILURE = 'PERSON_LIST_FAILURE';
export const PERSON_LIST_RESET = 'PERSON_LIST_RESET';

export const PERSON_UPDATE_REQUEST = 'PERSON_UPDATE_REQUEST';
export const PERSON_UPDATE_SUCCESS = 'PERSON_UPDATE_SUCCESS';
export const PERSON_UPDATE_FAILURE = 'PERSON_UPDATE_FAILURE';
export const PERSON_UPDATE_RESET = 'PERSON_UPDATE_RESET';

export const PERSON_CREATE_REQUEST = 'PERSON_CREATE_REQUEST';
export const PERSON_CREATE_SUCCESS = 'PERSON_CREATE_SUCCESS';
export const PERSON_CREATE_FAILURE = 'PERSON_CREATE_FAILURE';
export const PERSON_CREATE_RESET = 'PERSON_CREATE_RESET';

export const PERSON_DEL_REQUEST = 'PERSON_DEL_REQUEST';
export const PERSON_DEL_SUCCESS = 'PERSON_DEL_SUCCESS';
export const PERSON_DEL_FAILURE = 'PERSON_DEL_FAILURE';
export const PERSON_DEL_RESET = 'PERSON_DEL_RESET';

export const PERSON_DEL_CONFIRM_OPEN = 'PERSON_DEL_CONFIRM_OPEN';
export const PERSON_DEL_CONFIRM_OK = 'PERSON_DEL_CONFIRM_OK';
export const PERSON_DEL_CONFIRM_CANCEL = 'PERSON_DEL_CONFIRM_CANCEL';


export const UI_SHOW_PERSON_EDIT = 'UI_SHOW_PERSON_EDIT';
export const UI_CLOSE_PERSON_EDIT = 'UI_CLOSE_PERSON_EDIT';

export const UI_SHOW_PERSON_ADD = 'UI_SHOW_PERSON_ADD';
export const UI_CLOSE_PERSON_ADD = 'UI_CLOSE_PERSON_ADD';

var full = location.protocol+'//'+location.hostname+(location.port ? ':3001': '');

// EDIT

export const showPersonEditView = (personID) => {
  return {
    type: UI_SHOW_PERSON_EDIT,
    personID: personID,
  }
}

export const closePersonEditView = () => {
  return { type: UI_CLOSE_PERSON_EDIT }
}

// ADD

export const showPersonAddView = (personID) => {
  return {
    type: UI_SHOW_PERSON_ADD,
    personID: null,
  }
}

export const closePersonAddView = () => {
  return { type: UI_CLOSE_PERSON_ADD }
}

// LIST

export const getPersonList = (firmaID) => (dispatch) => {
  // Initiate loading state
  dispatch({
    type: PERSON_LIST_REQUEST
  });
  return fetch(`${full}/app/api/firma/${firmaID}/person`,{
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.authToken}`,
        }
      })
      .then(
        response => response.json()
      )
      .then(result => {
          // We can dispatch many times!
          // Here, we update the app state with the results of the API call.
          //console.log(result);
          return dispatch({
            type: PERSON_LIST_SUCCESS,
            ...result
          })
        }
      )
      .catch(err => {
        console.log(err);
        dispatch({
          type: PERSON_LIST_FAILURE,
          error: err
        })
      })

}

export const updatePerson = (firmaID, personJSON) => (dispatch) => {
  dispatch({
    type: PERSON_UPDATE_REQUEST
  });
  return fetch(`${full}/app/api/firma/${firmaID}/person`,{
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.authToken}`,
    },
    body: JSON.stringify(personJSON),
  })
  .then(
    response => response.json(),
    error => {
      console.log('An error occurred.', error);
    }
  )
  .then(result => {
      dispatch({
        type: PERSON_UPDATE_SUCCESS,
        result
      });
      //console.log("person: ",result);
      // Snackbar anzeigen
      dispatch(showSnackbar('Person gespeichert.'));
    }
  )
  .catch(err => {
    console.log(err);
    dispatch({
      type: PERSON_UPDATE_FAILURE,
      error: err
    })
  })

}


export const createPerson = (firmaID, personJSON) => (dispatch) => {
  dispatch({
    type: PERSON_CREATE_REQUEST
  });
  return fetch(`${full}/app/api/firma/${firmaID}/person`,{
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.authToken}`,
    },
    body: JSON.stringify(personJSON),
  })
  .then(
    response => response.json(),
    error => {
      console.log('An error occurred.', error);
    }
  )
  .then(result => {
      dispatch({
        type: PERSON_CREATE_SUCCESS,
        result
      });
      //console.log("person: ",result);
      // Snackbar anzeigen
      dispatch(showSnackbar('Person gespeichert.'));
    }
  )
  .catch(err => {
    console.log(err);
    dispatch({
      type: PERSON_CREATE_FAILURE,
      error: err
    })
  })

}

// Person löschen

export const deletePersonConfirm  = (id) => {
  return {
    type: PERSON_DEL_CONFIRM_OPEN,
    id
  };
}

export const deletePersonConfirmCancel  = () => {
  return {
    type: PERSON_DEL_CONFIRM_CANCEL
  };
}

export const deletePersonConfirmOk = () => {
  return {
    type: PERSON_DEL_CONFIRM_OK
  };
}



export const deletePerson = (firmaID,personID) => (dispatch) => {
  // Initiate loading state
  dispatch({
    type: PERSON_DEL_REQUEST
  });
  var jsondata = {PersonID: personID + ''};
  console.log('jsondata: ',jsondata);
  return fetch(`${full}/app/api/firma/${firmaID}/person`,{
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.authToken}`,
        },
        body: JSON.stringify(jsondata),
      })
      .then(
        response => response.json(),
        // Do not use catch, because that will also catch
        // any errors in the dispatch and resulting render,
        // causing a loop of 'Unexpected batch number' errors.
        // https://github.com/facebook/react/issues/6895
        error => {
          console.log('An error occurred.', error);
        }
      )
      .then(result => {
          // We can dispatch many times!
          // Here, we update the app state with the results of the API call.
          dispatch({
            type: PERSON_DEL_SUCCESS,
            personID
          });
          // Snackbar anzeigen
          dispatch(showSnackbar('Person gelöscht.'));
        }
      )
      .catch(err => {
        console.log(err);
        dispatch({
          type: PERSON_DEL_FAILURE,
          error: err
        })
      })

}