import { UI_SHOW_STANDORT_EDIT, UI_CLOSE_STANDORT_EDIT, UI_SHOW_STANDORT_ADD, UI_CLOSE_STANDORT_ADD,
  STANDORT_LIST_FAILURE, STANDORT_LIST_RESET, STANDORT_LIST_REQUEST, STANDORT_LIST_SUCCESS,
  STANDORT_UPDATE_REQUEST, STANDORT_UPDATE_FAILURE, STANDORT_UPDATE_SUCCESS,
  STANDORT_CREATE_REQUEST, STANDORT_CREATE_SUCCESS, STANDORT_CREATE_FAILURE,
  STANDORT_DEL_CONFIRM_OPEN, STANDORT_DEL_CONFIRM_CANCEL, STANDORT_DEL_CONFIRM_OK, STANDORT_DEL_REQUEST, STANDORT_DEL_FAILURE, STANDORT_DEL_SUCCESS} from '../actions/standort.js';


const INITIAL_STATE = {
  standorte: [],
  isLoading: false,
  didInvalidate: false,
  error: {},
  ui: { delConfirmOpen: false, isStandortEditing: false, standortID: null, mode: '' }
}


export const standort = (state = INITIAL_STATE, action) => {
  //console.log(action);
  switch (action.type) {
    case UI_SHOW_STANDORT_EDIT:
      return {
        ...state,
        ui: {isStandortEditing: true, standortID: action.standortID, mode: 'edit' },
      }
    case UI_CLOSE_STANDORT_EDIT:
      return {
        ...state,
        ui: {isStandortEditing: false, standortID: null, mode: '' },
      }
      case UI_SHOW_STANDORT_ADD:
        return {
          ...state,
          ui: {isStandortEditing: true, standortID: null, mode: 'add' },
        }
      case UI_CLOSE_STANDORT_ADD:
        return {
          ...state,
          ui: {isStandortEditing: false, standortID: null, mode: '' },
        }
    case STANDORT_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        didInvalidate: false,
      }
    case STANDORT_LIST_SUCCESS:

      return {
        ...state,
        standorte: action.standorte,
        isLoading: false,
        didInvalidate: false
      }
    case STANDORT_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
        didInvalidate: true
      }
    case STANDORT_LIST_RESET:
      return {
        ...INITIAL_STATE
      }
    case STANDORT_UPDATE_REQUEST:
    case STANDORT_CREATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        didInvalidate: false,
      }
    case STANDORT_UPDATE_FAILURE:
    case STANDORT_CREATE_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
        didInvalidate: true
      }
    case STANDORT_UPDATE_SUCCESS:
      return {
        ...state,
        standorte: { ...state.standorte, [action.result.standort.StandortID]: action.result.standort },
        isLoading: false,
        didInvalidate: false,
      }
    case STANDORT_CREATE_SUCCESS:
        return {
          ...state,
          standorte: { ...state.standorte, [action.result.standort.StandortID]: action.result.standort },
          ui: {...state.ui, standortID: action.result.standort.StandortID, mode: 'edit'},
          isLoading: false,
          didInvalidate: false,
        }
     // DELETE DIALOG
     case STANDORT_DEL_CONFIRM_OPEN:
      return {
        ...state,
        ui: {...state.ui, standortID: action.id, delConfirmOpen: true},
      }
    case STANDORT_DEL_CONFIRM_CANCEL:
      return {
        ...state,
        ui: {...state.ui, standortID: null, delConfirmOpen: false},
      }
    case STANDORT_DEL_CONFIRM_OK:
      return {
        ...state,
        //ui: {...state.ui, delConfirmOpen: false},
      }
    // DELETE
    case STANDORT_DEL_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case STANDORT_DEL_FAILURE:
      return {
        ...state,
        isLoading: false,
        didInvalidate: true,
        ui: {...state.ui, delConfirmOpen: false},
      }
    case STANDORT_DEL_SUCCESS:
      const {[action.standortID]: remove, ...newList} = state.standorte;
      return {
        ...state,
        //standorte: state.standorte.filter(t => t.ID != action.id),
        standorte: newList,
        isLoading: false,
        didInvalidate: false,
        ui: {...state.ui, delConfirmOpen: false},
      }

    default:
      return state
  }
}