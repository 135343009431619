import { RECHNUNG_JAHR_LIST_FAILURE, RECHNUNG_JAHR_LIST_REQUEST, RECHNUNG_JAHR_LIST_SUCCESS } from '../actions/rechnung.js';

const INITIAL_STATE = {
  rechnunglist: [],
  jahrsummen: {ivsGesamt: 0, ivsBlei: 0, ivsSonst: 0},
  isLoading: false,
  didInvalidate: false,
  error: {},
  jahr: 2019
}

export const rechnung = (state = INITIAL_STATE, action) => {
  //console.log(action);
  switch (action.type) {
    case RECHNUNG_JAHR_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        didInvalidate: false,
      }
    case RECHNUNG_JAHR_LIST_SUCCESS:
      return {
        ...state,
        rechnunglist: action.rechnungen,
        isLoading: false,
        didInvalidate: false,
        //jahrsummen: {ivsGesamt: 0, ivsBlei: ivsBleiSum, ivsSonst: 0}
      }
    case RECHNUNG_JAHR_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
        didInvalidate: true
      }

    default:
      return state
  }
}