import {showSnackbar} from './app.js';

export const MELDUNG_LIST_REQUEST = 'MELDUNG_LIST_REQUEST';
export const MELDUNG_LIST_SUCCESS = 'MELDUNG_LIST_SUCCESS';
export const MELDUNG_LIST_FAILURE = 'MELDUNG_LIST_FAILURE';
export const MELDUNG_LIST_RESET = 'MELDUNG_LIST_RESET';

export const MELDUNG_TYP_REQUEST = 'MELDUNG_TYP_REQUEST';
export const MELDUNG_TYP_SUCCESS = 'MELDUNG_TYP_SUCCESS';
export const MELDUNG_TYP_FAILURE = 'MELDUNG_TYP_FAILURE';
export const MELDUNG_TYP_RESET = 'MELDUNG_TYP_RESET';

export const MELDUNG_DATEN_REQUEST = 'MELDUNG_DATEN_REQUEST';
export const MELDUNG_DATEN_SUCCESS = 'MELDUNG_DATEN_SUCCESS';
export const MELDUNG_DATEN_FAILURE = 'MELDUNG_DATEN_FAILURE';
export const MELDUNG_DATEN_RESET = 'MELDUNG_DATEN_RESET';

export const MELDUNG_BYZYKLUS_REQUEST = 'MELDUNG_BYZYKLUS_REQUEST';
export const MELDUNG_BYZYKLUS_SUCCESS = 'MELDUNG_BYZYKLUS_SUCCESS';
export const MELDUNG_BYZYKLUS_FAILURE = 'MELDUNG_BYZYKLUS_FAILURE';
export const MELDUNG_BYZYKLUS_RESET = 'MELDUNG_BYZYKLUS_RESET';

export const MELDUNG_UPDATE_REQUEST = 'MELDUNG_UPDATE_REQUEST';
export const MELDUNG_UPDATE_SUCCESS = 'MELDUNG_UPDATE_SUCCESS';
export const MELDUNG_UPDATE_FAILURE = 'MELDUNG_UPDATE_FAILURE';
export const MELDUNG_UPDATE_RESET = 'MELDUNG_UPDATE_RESET';

export const MELDUNG_DEL_CONFIRM_OPEN = 'MELDUNG_DEL_CONFIRM_OPEN';
export const MELDUNG_DEL_CONFIRM_CANCEL = 'MELDUNG_DEL_CONFIRM_CANCEL';
export const MELDUNG_DEL_CONFIRM_OK = 'MELDUNG_DEL_CONFIRM_OK';

export const MELDUNG_DELETE_REQUEST = 'MELDUNG_DELETE_REQUEST';
export const MELDUNG_DELETE_SUCCESS = 'MELDUNG_DELETE_SUCCESS';
export const MELDUNG_DELETE_FAILURE = 'MELDUNG_DELETE_FAILURE';

export const UI_SHOW_MELDUNG_EDIT = 'UI_SHOW_MELDUNG_EDIT';
export const UI_CLOSE_MELDUNG_EDIT = 'UI_CLOSE_MELDUNG_EDIT';


var full = location.protocol+'//'+location.hostname+(location.port ? ':3001': '');


export const getMeldungList = (jahr) => (dispatch) => {
  // Initiate loading state
  dispatch({
    type: MELDUNG_LIST_REQUEST
  });
  return fetch(`${full}/app/api/meldung?jahr=${jahr}`,{
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.authToken}`,
        }
      })
      .then(
        response => response.json()
      )
      .then(result => {
          // We can dispatch many times!
          // Here, we update the app state with the results of the API call.
          //console.log(result);
          return dispatch({
            type: MELDUNG_LIST_SUCCESS,
            ...result
          })
        }
      )
      .catch(err => {
        console.log(err);
        dispatch({
          type: MELDUNG_LIST_FAILURE,
          error: err
        })
      })

}


export const getMeldungTypList = () => (dispatch) => {
  // Initiate loading state
  dispatch({
    type: MELDUNG_TYP_REQUEST
  });
  return fetch(`${full}/app/api/meldungstyp`,{
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.authToken}`,
        }
      })
      .then(
        response => response.json()
      )
      .then(result => {
          return dispatch({
            type: MELDUNG_TYP_SUCCESS,
            ...result
          })
        }
      )
      .catch(err => {
        console.log(err);
        dispatch({
          type: MELDUNG_TYP_FAILURE,
          error: err
        })
      })

}

// Meldung löschen

export const deleteMeldungConfirm  = (id) => {
  return {
    type: MELDUNG_DEL_CONFIRM_OPEN,
    id
  };
}

export const deleteMeldungConfirmCancel  = () => {
  return {
    type: MELDUNG_DEL_CONFIRM_CANCEL
  };
}

export const deleteMeldungConfirmOk = () => {
  return {
    type: MELDUNG_DEL_CONFIRM_OK
  };
}

// EDIT

export const showMeldungEditView = (zyklusID,mTypID,sbkID) => (dispatch) => {
  dispatch(getMeldungDaten(zyklusID)).then(() => {
      // Hack?
      if (mTypID == 3 && sbkID == 1) {
        // Meldung holen (wegen Überprüft-Flag)
        dispatch(getMeldungByZyklus(zyklusID));
      }
      dispatch( {
        type: UI_SHOW_MELDUNG_EDIT,
        zyklusID: zyklusID,
        mTypID: mTypID,
        sbkID: sbkID
      })
    }
  )

}

export const closeMeldungEditView = () => {
  return { type: UI_CLOSE_MELDUNG_EDIT }
}


// -----------------------------------------------------
// Meldung nach Zyklus-Id holen (für Behandlungsmeldung)

export const getMeldungByZyklus = (zyklusID) => (dispatch) => {
  // Initiate loading state
  dispatch({
    type: MELDUNG_BYZYKLUS_REQUEST
  });
  return fetch(`${full}/app/api/zyklus/meldung/${zyklusID}`,{
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.authToken}`,
        }
      })
      .then(
        response => response.json()
      )
      .then(result => {
          return dispatch({
            type: MELDUNG_BYZYKLUS_SUCCESS,
            meldung: result.meldung
          })
        }
      )
      .catch(err => {
        console.log(err);
        dispatch({
          type: MELDUNG_BYZYKLUS_FAILURE,
          error: err
        })
      })

}

export const getMeldungDaten = (zyklusID) => (dispatch) => {
  // Initiate loading state
  dispatch({
    type: MELDUNG_DATEN_REQUEST
  });
  return fetch(`${full}/app/api/meldung/${zyklusID}`,{
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.authToken}`,
        }
      })
      .then(
        response => response.json()
      )
      .then(result => {
          return dispatch({
            type: MELDUNG_DATEN_SUCCESS,
            meldungDaten: result.meldungDaten
          })
        }
      )
      .catch(err => {
        console.log(err);
        dispatch({
          type: MELDUNG_DATEN_FAILURE,
          error: err
        })
      })

}


export const updateMeldung = (zyklusID, meldungJSON) => (dispatch) => {
  dispatch({
    type: MELDUNG_UPDATE_REQUEST
  });
  return fetch(`${full}/app/api/meldung/${zyklusID}`,{
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.authToken}`,
    },
    body: JSON.stringify(meldungJSON),
  })
  .then(
    response => {
      if (response.ok) {
        return response.json();
      } else {
        dispatch(showSnackbar('Ein Fehler ist aufgetreten.'));
        throw new Error("Not 200 response")
      }
    },
    error => {
      console.log('An error occurred.', error);
    }
  )
  .then(result => {
      dispatch({
        type: MELDUNG_UPDATE_SUCCESS,
        result
      });
      //console.log("cron: ",result);
      // Snackbar anzeigen
      dispatch(showSnackbar('Meldung gespeichert.'));
    }
  )
  .catch(err => {
    console.log(err);
    dispatch({
      type: MELDUNG_UPDATE_FAILURE,
      error: err
    })
  })

}



export const deleteMeldung = (zyklusID) => (dispatch) => {
  // Initiate loading state
  dispatch({
    type: MELDUNG_DELETE_REQUEST
  });
  var jsondata = {ZyklusID: zyklusID + ''};
  console.log('jsondata: ',jsondata);
  return fetch(`${full}/app/api/meldung`,{
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.authToken}`,
        },
        body: JSON.stringify(jsondata),
      })
      .then(
        response => response.json(),
        // Do not use catch, because that will also catch
        // any errors in the dispatch and resulting render,
        // causing a loop of 'Unexpected batch number' errors.
        // https://github.com/facebook/react/issues/6895
        error => {
          console.log('An error occurred.', error);
        }
      )
      .then(result => {
          // We can dispatch many times!
          // Here, we update the app state with the results of the API call.
          dispatch({
            type: MELDUNG_DELETE_SUCCESS,
            zyklusID
          });
          // Snackbar anzeigen
          dispatch(showSnackbar('Meldung gelöscht.'));
        }
      )
      .catch(err => {
        console.log(err);
        dispatch({
          type: MELDUNG_DELETE_FAILURE,
          error: err
        })
      })

}



