import { UI_SHOW_PERSON_EDIT, UI_CLOSE_PERSON_EDIT, UI_SHOW_PERSON_ADD, UI_CLOSE_PERSON_ADD,
  PERSON_LIST_FAILURE, PERSON_LIST_RESET, PERSON_LIST_REQUEST, PERSON_LIST_SUCCESS,
  PERSON_UPDATE_REQUEST, PERSON_UPDATE_FAILURE, PERSON_UPDATE_SUCCESS,
  PERSON_CREATE_REQUEST, PERSON_CREATE_SUCCESS, PERSON_CREATE_FAILURE,
  PERSON_DEL_CONFIRM_OPEN, PERSON_DEL_CONFIRM_CANCEL, PERSON_DEL_CONFIRM_OK, PERSON_DEL_REQUEST, PERSON_DEL_FAILURE, PERSON_DEL_SUCCESS} from '../actions/person.js';


const INITIAL_STATE = {
  personen: [],
  isLoading: false,
  didInvalidate: false,
  error: {},
  ui: { delConfirmOpen: false, isPersonEditing: false, personID: null, mode: '' }
}


export const person = (state = INITIAL_STATE, action) => {
  //console.log(action);
  switch (action.type) {
    case UI_SHOW_PERSON_EDIT:
      return {
        ...state,
        ui: {isPersonEditing: true, personID: action.personID, mode: 'edit' },
      }
    case UI_CLOSE_PERSON_EDIT:
      return {
        ...state,
        ui: {isPersonEditing: false, personID: null, mode: '' },
      }
      case UI_SHOW_PERSON_ADD:
        return {
          ...state,
          ui: {isPersonEditing: true, personID: null, mode: 'add' },
        }
      case UI_CLOSE_PERSON_ADD:
        return {
          ...state,
          ui: {isPersonEditing: false, personID: null, mode: '' },
        }
    case PERSON_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        didInvalidate: false,
      }
    case PERSON_LIST_SUCCESS:

      return {
        ...state,
        personen: action.personen,
        isLoading: false,
        didInvalidate: false
      }
    case PERSON_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
        didInvalidate: true
      }
    case PERSON_LIST_RESET:
      return {
        ...INITIAL_STATE
      }
    case PERSON_UPDATE_REQUEST:
    case PERSON_CREATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        didInvalidate: false,
      }
    case PERSON_UPDATE_FAILURE:
    case PERSON_CREATE_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
        didInvalidate: true
      }
    case PERSON_UPDATE_SUCCESS:
      return {
        ...state,
        personen: { ...state.personen, [action.result.person.PersonID]: action.result.person },
        isLoading: false,
        didInvalidate: false,
      }
    case PERSON_CREATE_SUCCESS:
        return {
          ...state,
          personen: { ...state.personen, [action.result.person.PersonID]: action.result.person },
          ui: {...state.ui, personID: action.result.person.PersonID, mode: 'edit'},
          isLoading: false,
          didInvalidate: false,
        }
     // DELETE DIALOG
     case PERSON_DEL_CONFIRM_OPEN:
      return {
        ...state,
        ui: {...state.ui, personID: action.id, delConfirmOpen: true},
      }
    case PERSON_DEL_CONFIRM_CANCEL:
      return {
        ...state,
        ui: {...state.ui, personID: null, delConfirmOpen: false},
      }
    case PERSON_DEL_CONFIRM_OK:
      return {
        ...state,
        //ui: {...state.ui, delConfirmOpen: false},
      }
    // DELETE
    case PERSON_DEL_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case PERSON_DEL_FAILURE:
      return {
        ...state,
        isLoading: false,
        didInvalidate: true,
        ui: {...state.ui, delConfirmOpen: false},
      }
    case PERSON_DEL_SUCCESS:
      const {[action.personID]: remove, ...newList} = state.personen;
      return {
        ...state,
        //personen: state.personen.filter(t => t.ID != action.id),
        personen: newList,
        isLoading: false,
        didInvalidate: false,
        ui: {...state.ui, delConfirmOpen: false},
      }

    default:
      return state
  }
}