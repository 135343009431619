import { REPORT_JAHR_LIST_FAILURE, REPORT_JAHR_LIST_SUCCESS, REPORT_JAHR_LIST_REQUEST,
  JAHRELIST_FAILURE, JAHRELIST_SUCCESS, JAHRELIST_REQUEST} from '../actions/report.js';


const INITIAL_STATE = {
  jahrreport: [],
  jahrsummen: {ivsGesamt: 0, ivsBlei: 0, ivsSonst: 0},
  isLoading: false,
  didInvalidate: false,
  error: {},
  jahr: 2019
}


export const report = (state = INITIAL_STATE, action) => {
  //console.log(action);
  switch (action.type) {
    case REPORT_JAHR_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        didInvalidate: false,
      }
    case REPORT_JAHR_LIST_SUCCESS:
      return {
        ...state,
        jahrreport: action.report,
        isLoading: false,
        didInvalidate: false,
        //jahrsummen: {ivsGesamt: 0, ivsBlei: ivsBleiSum, ivsSonst: 0}
      }
    case REPORT_JAHR_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
        didInvalidate: true
      }
    case JAHRELIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        didInvalidate: false,
      }
    case JAHRELIST_FAILURE:
        return {
          ...state,
          error: action.error,
          isLoading: false,
          didInvalidate: true
        }
    case JAHRELIST_SUCCESS:
        return {
          ...state,
          jahrelist: action.jahrelist,
          isLoading: false,
          didInvalidate: false
        }
    default:
      return state
  }
}




