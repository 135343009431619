// zum Holen der Jahre
export const JAHRELIST_REQUEST = 'JAHRELIST_REQUEST';
export const JAHRELIST_SUCCESS = 'JAHRELIST_SUCCESS';
export const JAHRELIST_FAILURE = 'JAHRELIST_FAILURE';

export const REPORT_JAHR_LIST_REQUEST = 'REPORT_JAHR_LIST_REQUEST';
export const REPORT_JAHR_LIST_SUCCESS = 'REPORT_JAHR_LIST_SUCCESS';
export const REPORT_JAHR_LIST_FAILURE = 'REPORT_JAHR_LIST_FAILURE';
export const REPORT_JAHR_LIST_RESET = 'REPORT_JAHR_LIST_RESET';

var full = location.protocol+'//'+location.hostname+(location.port ? ':3001': '');

export const getJahrelist = () => (dispatch) => {
  dispatch({
    type: JAHRELIST_REQUEST
  });
  return fetch(`${full}/app/api/report/jahrelist`,{
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.authToken}`,
    }
  })
  .then(
    response => response.json()
  )
  .then(result => {
      // We can dispatch many times!
      // Here, we update the app state with the results of the API call.
      //console.log(result);
      return dispatch({
        type: JAHRELIST_SUCCESS,
        ...result
      })
    }
  )
  .catch(err => {
    console.log(err);
    dispatch({
      type: JAHRELIST_FAILURE,
      error: err
    })
  })

}

export const getReportJahrList = (jahr) => (dispatch) => {
  // Initiate loading state
  dispatch({
    type: REPORT_JAHR_LIST_REQUEST
  });
  return fetch(`${full}/app/api/report/jahr/${jahr}`,{
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.authToken}`,
        }
      })
      .then(
        response => response.json()
      )
      .then(result => {
          // We can dispatch many times!
          // Here, we update the app state with the results of the API call.
          //console.log(result);
          return dispatch({
            type: REPORT_JAHR_LIST_SUCCESS,
            ...result
          })
        }
      )
      .catch(err => {
        console.log(err);
        dispatch({
          type: REPORT_JAHR_LIST_FAILURE,
          error: err
        })
      })

}